.header {
    background-color: transparent;
    height: 170px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 20px;

    .header-logo {
        img {
            object-fit: cover;
            height: 80px;
        }
    }

    .header-nav {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        padding-top: 5px;

        .burger-menu {
            width: 40px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-bottom: 20px;

            span {
                height: 2px;
                background-color: #6f6f6f;
                margin-bottom: 10px;
                transition: 0.3s;
            }
            span:first-child {
                width: 100%;
            }
            span:nth-child(2) {
                width: 80%;
            }
            span:last-child {
                width: 100%;
            }
        }

        .burger-menu:hover {
            cursor: pointer;
            span:nth-child(2) {
                width: 100%;
            }
        }

        .header-list {
            background-color: #c59e9366;
            width: 150px;
            position: absolute;
            right: 200vh;
            list-style: none;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 45px;
            transition: all 0.5s ease-in-out;

            li {
                a {
                    text-decoration: none;
                    color: black;
                    font-size: 1.5rem;
                    transition: 0.3s;
                }

                a:hover {
                    color: red;
                    padding-right: 40px;
                }
            }
        }

        .header-list::before {
            content: '';
            border-width: 15px;
            border-style: solid;
            border-color: transparent transparent #c59e9366 transparent;
            position: absolute;
            right: 5px;
            top: -30px;
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 3000px) {
    .header {
        .header-logo {
            img {
                height: 120px;
            }
        }

        .header-nav {
            .burger-menu {
                display: none;
            }

            .header-list {
                display: block;
                right: 0;
                background-color: transparent;
                width: 500px;
                position: absolute;
                list-style: none;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-around;
            }

            .header-list::before {
                content: '';
                border-width: 0px;
                border-color: transparent transparent transparent transparent;
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }
    }
}
