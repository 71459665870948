.about-brand {
    margin-top: 5rem;
    text-align: center;

    h2 {
        color: red;
    }

    p {
        line-height: 3rem;
        width: 80%;
        margin: auto;
        font-size: 1.3rem;
    }
}

@media only screen and (min-width: 600px) {
    .about-brand {
        p {
            width: 60%;
            font-size: 1.6rem;
        }
    }
}
