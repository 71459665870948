.hero-section {
    background-image: url(../../images/pexels-magda-ehlers-3158017.jpg);
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0 1rem 1rem 1rem;
    height: 30rem;
    .hero-main {
        .hero-logo {
            margin: 1rem;
            width: 200px;
            img {
                object-fit: cover;
                object-position: center;
                margin-left: -2rem;
                width: 100%;
            }
        }

        .hero-content {
            margin-top: 2.2rem;
            font-size: 1.7rem;
            color: rgb(192, 21, 78);
            width: 100%;
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 3000px) {
    .hero-section {
        height: 50rem;
        .hero-main {
            .hero-logo {
                width: 22%;
                margin-left: 3rem;
            }
            .hero-content {
                width: 40%;
                font-size: 3rem;
                text-align: center;
                padding: 0px 20px;
            }
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
    .hero-section {
        height: 50rem;
        .hero-main {
            .hero-logo {
                width: 30%;
                margin: 3rem;
            }
            .hero-content {
                font-size: 2rem;
                width: 40%;
                text-align: center;
                padding: 20px;
            }
        }
    }
}
