body {
    background-image: linear-gradient(#e5e5e5, white);
    border: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Harmattan', sans-serif;
    display: grid;
    grid-template-columns: 1fr;
}

.contact-us {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 6rem auto 0 auto;

    span {
        margin: 1rem;

        a {
            cursor: pointer;
        }
    }
}

.footer {
    width: 100%;
    height: 3.125rem;
    color: #010101;
    background-color: #caccb5;
    text-align: center;
    padding-top: 0.5rem;
    font-size: 1.4rem;
    margin-top: 2rem;
}
