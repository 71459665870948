.header {
  background-color: transparent;
  height: 170px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding-top: 20px;
}

.header .header-logo img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 80px;
}

.header .header-nav {
  width: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  position: relative;
  padding-top: 5px;
}

.header .header-nav .burger-menu {
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-bottom: 20px;
}

.header .header-nav .burger-menu span {
  height: 2px;
  background-color: #6f6f6f;
  margin-bottom: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.header .header-nav .burger-menu span:first-child {
  width: 100%;
}

.header .header-nav .burger-menu span:nth-child(2) {
  width: 80%;
}

.header .header-nav .burger-menu span:last-child {
  width: 100%;
}

.header .header-nav .burger-menu:hover {
  cursor: pointer;
}

.header .header-nav .burger-menu:hover span:nth-child(2) {
  width: 100%;
}

.header .header-nav .header-list {
  background-color: #c59e9366;
  width: 150px;
  position: absolute;
  right: 200vh;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 45px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.header .header-nav .header-list li a {
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.header .header-nav .header-list li a:hover {
  color: red;
  padding-right: 40px;
}

.header .header-nav .header-list::before {
  content: '';
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent #c59e9366 transparent;
  position: absolute;
  right: 5px;
  top: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 3000px) {
  .header .header-logo img {
    height: 120px;
  }
  .header .header-nav .burger-menu {
    display: none;
  }
  .header .header-nav .header-list {
    display: block;
    right: 0;
    background-color: transparent;
    width: 500px;
    position: absolute;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .header .header-nav .header-list::before {
    content: '';
    border-width: 0px;
    border-color: transparent transparent transparent transparent;
    position: absolute;
    right: 0px;
    top: 0px;
  }
}

body {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#e5e5e5), to(white));
  background-image: linear-gradient(#e5e5e5, white);
  border: 0;
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: 'Harmattan', sans-serif;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}

.contact-us {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 6rem auto 0 auto;
}

.contact-us span {
  margin: 1rem;
}

.contact-us span a {
  cursor: pointer;
}

.footer {
  width: 100%;
  height: 3.125rem;
  color: #010101;
  background-color: #caccb5;
  text-align: center;
  padding-top: 0.5rem;
  font-size: 1.4rem;
  margin-top: 2rem;
}

.hero-gallery {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-gap: 0.2rem;
}

.hero-gallery .gallery-image img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media only screen and (min-width: 992px) and (max-width: 2800px) {
  .hero-gallery {
    -ms-grid-columns: (1fr)[6];
        grid-template-columns: repeat(6, 1fr);
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .hero-gallery {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
  }
}

.hero-section {
  background-image: url(../../images/pexels-magda-ehlers-3158017.jpg);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0 1rem 1rem 1rem;
  height: 30rem;
}

.hero-section .hero-main .hero-logo {
  margin: 1rem;
  width: 200px;
}

.hero-section .hero-main .hero-logo img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  margin-left: -2rem;
  width: 100%;
}

.hero-section .hero-main .hero-content {
  margin-top: 2.2rem;
  font-size: 1.7rem;
  color: #c0154e;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 3000px) {
  .hero-section {
    height: 50rem;
  }
  .hero-section .hero-main .hero-logo {
    width: 22%;
    margin-left: 3rem;
  }
  .hero-section .hero-main .hero-content {
    width: 40%;
    font-size: 3rem;
    text-align: center;
    padding: 0px 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .hero-section {
    height: 50rem;
  }
  .hero-section .hero-main .hero-logo {
    width: 30%;
    margin: 3rem;
  }
  .hero-section .hero-main .hero-content {
    font-size: 2rem;
    width: 40%;
    text-align: center;
    padding: 20px;
  }
}

.about-brand {
  margin-top: 5rem;
  text-align: center;
}

.about-brand h2 {
  color: red;
}

.about-brand p {
  line-height: 3rem;
  width: 80%;
  margin: auto;
  font-size: 1.3rem;
}

@media only screen and (min-width: 600px) {
  .about-brand p {
    width: 60%;
    font-size: 1.6rem;
  }
}
/*# sourceMappingURL=style.css.map */